body {
	font-family: 'Titillium Web', sans-serif;
	margin: 0;
}

h1 {
	color: #e3e6ea;
	font-size: 50px;
	font-weight: normal;
	margin-bottom: 0;
}

h2 {
	color: white;
	font-size: 18px;
	margin-top: 0;
}

h3 {
  text-align: center;
  font-size: 28px;
  font-weight: normal;
  margin-top: 100px;
}

h4 {
  text-align: center;
  font-size: 24px;
}

.h5 {
  text-align: left;
  font-weight: bold;
}

p {
  text-align: center;
}

.about-container {
	background: url(./assets/bg.jpg) no-repeat center center fixed; 
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	background-size: cover;
	height: 600px;
}

.bio {
	color: white;
	font-size: 14px;
}

.bio_photo{
	height: 150px;
	border-radius: 100%;
	border-style: solid;
	border-color: white;
	margin-top: 40px;
}

.about{
	width: 400px;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}

.hr_grey{
	height: 3px;
	background-color: #b8bcc4;
	border: none;
	width: 50px;
}

.yellow {
  border-top: 5px double #F5A623;
  width: 250px;
  border-bottom: none;
  border-right: none;
}

.box {
  float: left;
  width: 42.5%;
  margin-left: 5%;
  margin-top: 80px;
}

.clear {
  clear: both;
}

.full-width {
  width: 100%;
}

.skills-and-tools {
  max-width: 400px;
  margin: 40px auto;
}

.icons {
  text-align: center;
}

.icon {
  margin: 30px 0;
}

.contact-me {
  height: 350px;
  background-color: #202020;
}

.contact-headline {
  color: white;
  background-color: #202020;
  padding-top: 30px;
}

.contact-info {
  color: white;
  font-size: 13px;
  margin-top: 30px;
}

.contact-link {
  color: white;
  font-weight: bold;
  font-size: 13px;
}

.nav-link {
  color: white;
  text-decoration: none;
}

li {
  display: inline-block;
  font-size: 12px;
  width: 100px;
  font-weight: bold;
  margin-top: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  margin-left: 30px;
}

.hire-me {
  border-style: solid;
  border-color: white;
}
